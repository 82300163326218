<div class="m-2 p-2">
  <div class="windx">
    <h4 class="ilatitle">Akteneinsicht beantragen</h4>
    <form>
      <div class="form-text">Empfänger-Faxnummer:
      </div>
      <div style="font-weight: bold" *ngIf="receiver_fax"> +{{receiver_fax}}</div>
      <div style="color: red; font-weight: bold;" *ngIf="!receiver_fax"> keine Nummer vorhanden</div>
      <span class="form-text">Beantragungs-Art</span>
      <select class="form-control" [(ngModel)]="einsichtType" name="einsicht_type">
        <option value="1">für CL nach LE</option>
        <option value="2">für VG nach HD</option>
        <option disabled value="3">(für mich an ein beliebiges NLG)</option>
        <option disabled value="4">(für jemand anderen an ein beliebiges NLG)</option>
        <option disabled value="5">(für mich Ankündigung Besuch NLG)</option>
        <option disabled value="6">(für jemand anderen Ankündigung Besuch NLG)</option>
      </select>
      <div *ngIf="einsichtType == 4 || einsichtType == 6">
        <span class="form-text">Beantragung für:</span>
        <editfield-select class="form-control" [(select)]="einsichtPerson" [editMode]="true" [options]="userOptions"></editfield-select>
      </div>
      <div *ngIf="einsichtType == 3 || einsichtType == 4 || einsichtType == 5 || einsichtType == 6">
        <span class="form-text">Beantragung nach:</span>
        <addressbook-autocomplete [editMode]="true" [(fieldValue)]="einsichtGericht"></addressbook-autocomplete>
      </div>
      <div *ngIf="einsichtType == 5 || einsichtType == 6">
        <span class="form-text">Einsichtnahme am:</span>
        <input type="date" class="form-control" [(ngModel)]="einsichtDatum" name="einsicht_datum">
      </div>
    </form>
  </div>
  <div class="mt-2">
    <p *ngIf="error" style="color: red; font-weight: bold; font-size: 80%">Technischer Fehler beim Fax-Versand, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut</p>
    <button class="mx-2 btn btn-sm btn-danger" (click)="cancel()">abbrechen</button>
    <button [disabled]="inProgress || !receiver_fax" class="mx-2 btn btn-sm btn-success" (click)="send()">absenden</button>
  </div>
</div>
