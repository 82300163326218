<div>
  <h4>{{componentHeadline}} <generate-doc-button *ngIf="false" [mode]="mode" [activeCaseId]="activeCaseId" [activePersonId]="activePersonId" [caseIdForActivePerson]="caseIdForActivePerson"></generate-doc-button></h4>
  <ngb-alert *ngIf="moving" [dismissible]="false">Die Datei wird verschoben.</ngb-alert>
  <ngb-alert *ngIf="moving_done && !error" [dismissible]="false" [type]="'success'">Die Datei wurde erfolgreich verschoben</ngb-alert>
  <ngb-alert *ngIf="moving_done && error" [dismissible]="false" [type]="'danger'">Übertragungsfehler bei der Verschiebung der Datei</ngb-alert>
  <ngb-alert *ngIf="renaming" [dismissible]="false">Die Datei wird umbenannt.</ngb-alert>
  <ngb-alert *ngIf="renaming_done && !error" [dismissible]="false" [type]="'success'">Die Datei wurde erfolgreich umbenannt</ngb-alert>
  <ngb-alert *ngIf="renaming_done && error" [dismissible]="false" [type]="'danger'">Übertragungsfehler bei der Umbenennung der Datei</ngb-alert>
  <ngb-alert *ngIf="deleting" [dismissible]="false">Die Datei wird gelöscht.</ngb-alert>
  <ngb-alert *ngIf="deleting_done && !error" [dismissible]="false" [type]="'success'">Die Datei wurde erfolgreich gelöscht</ngb-alert>
  <ngb-alert *ngIf="deleting_done && error" [dismissible]="false" [type]="'danger'">Übertragungsfehler beim Löschen der Datei</ngb-alert>
  <ngb-alert *ngIf="no_folder" [dismissible]="false" [type]="'danger'">Fehler: Es wurde kein Zielordner angegeben</ngb-alert>

  <p>
    <button class="mx-1 btn btn-sm btn-success" (click)="uploadDocuments()">Dokumente hochladen</button>
  </p>

</div>

<form class="form-inline m-1" [formGroup]="filterForm">
  <label for="formFilter" [ngClass]="(filterstring=='')?'badge badge-success':'badge badge-danger'">Filtern nach</label>
  <input type="text" class="form-control form-control-sm m-1" id="formFilter" formControlName="searchString" placeholder="Bezeichnung, Dateiname...">
</form>
<div class="table-responsive">
  <ngb-tabset (tabChange)="tabChange($event)">
    <div *ngFor="let folder of folderList">
      <ngb-tab id="{{folder.id}}" title="{{folder.description}}">
        <ng-template ngbTabContent>
          <div *ngIf="copiedFileUploads && copiedFileUploads.length>0">
            <table class="table table-hover table-striped table-sm">
              <ng-container *ngFor="let item of copiedFileUploads">
                <tr (click)="onClicked(item)" *ngIf="item.folder_id==folder.id">
                  <td>{{item.title}}</td>
                  <td>{{item.file_name}}</td>
                  <td>{{item.created | date: 'dd.MM.yyyy'}}</td>
                  <td>{{item.description}}</td>
                  <td><span class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(item.user_id)}}</span></td>
                  <td>
                    <span *ngIf="item.fax_status !== null && item.fax_status <= 3" title="Fax-Übertragung läuft" class="fa fa-hourglass" style="color: orange;"></span>
                    <span *ngIf="item.fax_status !== null && item.fax_status == 4" title="Fax-Übertragung erfolgreich" class="fa fa-check" style="color: green;"></span>
                    <span *ngIf="item.fax_status !== null && item.fax_status == 5" title="Fax-Übertragung fehlgeschlagen" class="fa fa-times" style="color: red;"></span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
          <div *ngIf="!copiedFileUploads || copiedFileUploads.length == 0" style="min-height: 30px;" class="mb-4">
            keine Dateien in diesem Ordner<br>
          </div>
        </ng-template>
      </ngb-tab>
    </div>
    <ngb-tab *ngIf="false" id="generated-documents" title="erzeugte Dokumente">
      <ng-template ngbTabContent>
        <list-docs></list-docs>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>

  <div>
    <div *ngIf="copiedFileUploads && copiedFileUploads.length > 0">
      <select *ngIf="copiedFileUploads" [(ngModel)]="fileToMove">
        <option [value]="null">bitte auswählen</option>
        <option *ngFor="let item of copiedFileUploads" [value]="item.id">{{item.file_name}} {{item.title}} {{item.created | date: 'dd.MM.yyyy'}}</option>
      </select>
      <select *ngIf="copiedFileUploads" [(ngModel)]="targetFolder">
        <option *ngFor="let item of folderList" [value]="item.id">{{item.description}}</option>
      </select>
      <button class="btn btn-sm btn-warning" (click)="changeFolder()">Datei verschieben</button>
    </div>
    <div *ngIf="copiedFileUploads && copiedFileUploads.length > 0">
      <select *ngIf="copiedFileUploads" [(ngModel)]="fileToRename">
        <option [value]="null">bitte auswählen</option>
        <option *ngFor="let item of copiedFileUploads" [value]="item.id">{{item.file_name}} {{item.title}} {{item.created | date: 'dd.MM.yyyy'}}</option>
      </select>
      <input [(ngModel)]="file_title_new" placeholder="neuer Dateiname" maxlength="100" type="text">
      <input [(ngModel)]="title_new" placeholder="neuer Titel" maxlength="50" type="text">
      <input [(ngModel)]="file_description_new" placeholder="neuer Kommentar" type="text">
      <button class="btn btn-sm btn-info" (click)="renameFile()">Datei umbenennen</button>
    </div>
    <div *ngIf="rightsManagementService.isAllowedToDeleteFiles() && copiedFileUploads && copiedFileUploads.length > 0">
      <select [(ngModel)]="fileToDelete">
        <option [value]="null">bitte auswählen</option>
        <option *ngFor="let item of copiedFileUploads" [value]="item.id">{{item.file_name}} {{item.title}} {{item.created | date: 'dd.MM.yyyy'}}</option>
      </select>
      <button class="btn btn-sm btn-danger" (click)="delete()">Datei löschen</button>
    </div>
  </div>
</div>
<div style="height: 20px;"> </div>
